import $ from 'jquery';
import { Datepicker, registerComponent, Input, localeJa as mbLocaleJa } from '@mobiscroll/jquery';
import * as bootstrap from 'bootstrap';
import { DateTime } from "luxon";
window.DateTime = DateTime;

import * as IC from '../custom/in_control';
import { confirmationDialog } from '../custom/confirmation';
import * as bsValidations from '../custom/bootstrap/validations';
import enableBootstrapTooltips from '../custom/bootstrap/tooltips';
import enableBootstrapPopovers from '../custom/bootstrap/popovers';
import enableBootstrapToasts from '../custom/bootstrap/toasts';

const CAMPAIGN_CONTENT_BOTTOM_MARGIN_OFFSET = 32;

const sortable = require("html5sortable/dist/html5sortable.cjs.js") ;

registerComponent(Datepicker);
registerComponent(Input);

console.log('[LOAD] reservations/meeting_reservations');

var startDate      = null;
var endDate        = null;
var availabilities = null;

function sanitizeDate(date) {
  date.setHours(0, 0, 0, 0);
  return date;
}

function getStartDate() {
  if(!startDate)
    startDate = sanitizeDate(new Date($('#meeting_campaign_start_date').data('date')));
  return startDate;
}

function getEndDate() {
  if(!endDate)
    endDate = sanitizeDate(new Date($('#meeting_campaign_end_date').data('date')));
  return endDate;
}

function getToday() {
  return sanitizeDate(new Date);
}


function getAvailabilities() {
  if(!availabilities) {
    availabilities = [];

    $('#meeting_availabilities .meeting-availability').each(function(_mi, ma) {
      ma = $(ma);
      let entry = {
        valid:       ma.data('valid'),
        weekdayName: ma.data('weekday-name'),
        startTime:   ma.data('start-time'),
        endTime:     ma.data('end-time'),
        timeOptions: $(ma.html()),
      };
      entry.startHour   = entry.startTime.split(':')[0];
      entry.startMinute = entry.startTime.split(':')[1];
      entry.endHour   = entry.endTime.split(':')[0];
      entry.endMinute = entry.endTime.split(':')[1];
      availabilities[ma.data('weekday')] = entry;
    });
  }
  return availabilities;
}


function getChoiceCount() {
  return $('.reservation-form .choices .choice').length;
}

function newChoice() {
  let newId     = IC.icRandomDomId();
  let newChoice = $('.reservation-form .template .choice').clone();

  newChoice.find(':input, [data-rewrite-id]').each(function(i, input) {
    input = $(input);

    let attrName = input.is(':input') ? 'id' : input.data('rewrite-id');
    let oldVal   = $(input).attr(attrName);
    if(!_.isEmpty(oldVal))
      $(input).attr(attrName, oldVal.replace(/template/, newId));
    if(input.is(':input'))
      $(input).attr('disabled', null)
  });
  return newChoice;

}

function addChoice(event, inst) {
  if(getChoiceCount() >= 3) {
    event.domEvent.stopImmediatePropagation();

    let toastContainer = $('#reservation_toast');
    let toast = bootstrap.Toast.getInstance(toastContainer.get(0));

    toast.hide();
    toastContainer.addClass('bg-danger text-white');
    toastContainer.find('.toast-body').html('既に面談御希望日を 3 件選択しています。');
    toast.show();
    return false;
  }

  let choice       = newChoice();
  let calendar     = $(event.target).closest('.participant-date-picker');
  let participant  = calendar.closest('.participant');
  let availability = getAvailabilities()[event.date.getDay()];

  // Set human visible fields
  choice.find('.participant-name').html(participant.find('.participant-name').html());

  choice.find('.date .year').html(event.date.getFullYear());
  choice.find('.date .month').html(event.date.getMonth() + 1);
  choice.find('.date .day').html(event.date.getDate());
  choice.find('.date .weekday').html(availability.weekdayName);

  let timeOptions  = availability.timeOptions.clone();
  choice.find('[id$="start_time"]').html(timeOptions);

  // Set hidden form fields
  let eventDate = DateTime.local(event.date.getFullYear(), event.date.getMonth() + 1, event.date.getDate());
  choice.find('input[id$="meeting_resource_id"]').val(calendar.data('meeting-resource-id'));
  choice.find('input[id$="date"]').val(eventDate.toISODate());

  $('.reservation-form .choices').append(choice);
  reinitializeSortableForAllChoices();
  setCampaignContentBottomMargin();

  updateCalendarLabels();

  return true;
}

  // Set label on calendars.
function updateCalendarLabels() {
  $('.participants-list .participant-date-picker').each(function(_pi, participant) {
    participant = $(participant);
    let pCalendar = participant.mobiscroll('getInst');

    participant.find('.mbsc-calendar-labels .mbsc-calendar-label').remove();

    // Find reservations for participant.
    let pResourceId = participant.data('meeting-resource-id');
    let pChoices    = [];
    let pDates      = [];

    $('.reservation-form .choices .choice').each(function(ci, choice) {
      choice = $(choice);
      if(choice.find('input[id$="_meeting_resource_id"]').val() == pResourceId) {
        let pDate = new Date(choice.find('[id$="_date"]').val());

        pChoices.push({
          date: pDate,
          text: `<div class="text-danger text-center">#${ci + 1}</div>`,
          color: 'transparent'
        });

        let tDate = DateTime.local(pDate.getFullYear(), pDate.getMonth() + 1, pDate.getDate());
        pDates.push(tDate.toISODate());
      }
    });
    pCalendar.setVal(pDates.join(','));
    pCalendar.setOptions({ labels: pChoices });
  });
}

function initializeChoiceActions() {
  $('.reservation-form .choices').on('click', '.choice .actions .btn', function(evt) {
    let clicked = $(this);

    if(clicked.is('.action-destroy')) {
      evt.stopImmediatePropagation();

      confirmationDialog.find('.modal-body').html('面談希望日を削除しますか？');
      confirmationDialog.doOk = function() {
        clicked.closest('.choice').remove();
        updateCalendarLabels();
      }
      bootstrap.Modal.getInstance(confirmationDialog.get(0)).show();
    }
  });
}

function initializeChoiceValidations() {
  $('.reservation-form FORM').on('submit', function(evt) {
    let form = $(this);

    if(getChoiceCount() == 0) {
      evt.stopImmediatePropagation();

      let toastContainer = $('#reservation_toast');
      let toast = bootstrap.Toast.getInstance(toastContainer.get(0));

      toast.hide();
      toastContainer.addClass('bg-danger text-white');
      toastContainer.find('.toast-body').html('面談希望日を選択してください。');
      toast.show();
      return false;
    } else if(!form.is('.confirmed')) {
      evt.stopImmediatePropagation();
      confirmationDialog.find('.modal-body').html('面談を予約しますか？');
      confirmationDialog.doOk = function() {
        $('.reservation-form FORM').addClass('confirmed').submit();
      }
      bootstrap.Modal.getInstance(confirmationDialog.get(0)).show();
      return false;
    } else {
      return true;
    }
  });
}

// Initializes Mobiscroll calender for MeetingResources located at given node.
// @param  resource [MeetingResource] The target
function initializeMeetingResourceDateControls(resource) {
  let picker  = $(resource).find('.participant-date-picker');
  let options = {
    controls: ['calendar'],
    selectMultiple: true,
    selectMin: 1,
    display: 'inline',
    dateFormat: 'YYYY-MM-DD',
    locale: mbLocaleJa,
    invalid: [{ recurring: { repeat: 'daily', interval: 1 } }],
    onCellClick: addChoice
  };

  let readOnly  = picker.is('.cant-reserve');
  let busyDays  = _.map(picker.data('busy-dates').split(','), function(d) {
    return new Date(_.trim(d)).toDateString();
  });
  let validDays = [];
  let today     = getToday();

  for(let d = new Date(getStartDate()); d <= getEndDate(); IC.incrementDate(d)) {
    if(d <= today)
      continue;
    if(busyDays.includes(d.toDateString()))
      continue;

    let ma = getAvailabilities()[d.getDay()];
    if (ma.valid != true)
      continue;

    validDays.push(new Date(d));
  }
  if(!_.isEmpty(validDays)) {
    options.colors = _.map(validDays, function(d) {
      return {
        date:       d,
        background: '#d1e7dd'
      };
    });
    if(!readOnly)
      options.valid = validDays;
  }

  // Initialize date pickers separately, so that we can manipulte their labels and values independently.
  picker.each(function(_pi, pick) {
    pick = $(pick);
    pick.mobiscroll().datepicker(options);

    let calendar = pick.mobiscroll('getInst');
    calendar.navigate(getStartDate());
  });
}

// Initializes Mobiscroll calendar for ALL MeetingResources.
function initializeAllMeetingResourceDateControls() {
  $('.participants-list .participant').each(function(_pi, participant) {
    initializeMeetingResourceDateControls(participant);
  });
}


function reinitializeSortableForAllChoices() {
  sortable('.allow-sort', {
    item:   '.choice',
    handle: '.sort-handle'
  });
}

function initializeSortableForAllChoices() {
  reinitializeSortableForAllChoices();
}


function setCampaignContentBottomMargin() {
  let footerHeight = $('.reservation-form').outerHeight() || 0;
  $('.campaign-content').css('marginBottom', footerHeight + CAMPAIGN_CONTENT_BOTTOM_MARGIN_OFFSET);
}

function initializeCampaignContentBottomMargin() {
  $(window).on('resize orientationchange', function() {
    setCampaignContentBottomMargin();
  })
}


function icInitializeBootstrapMain() {
  bsValidations.enable();
  enableBootstrapTooltips();
  enableBootstrapPopovers();
  enableBootstrapToasts();

  initializeAllMeetingResourceDateControls();
  initializeSortableForAllChoices();
  initializeChoiceValidations();
  initializeChoiceActions();
  setCampaignContentBottomMargin();
}

window.icInitializeBootstrapMain = icInitializeBootstrapMain;
