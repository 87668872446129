import $ from 'jquery';
import * as bootstrap from 'bootstrap';

export default function enableBootstrapPopovers(root) {
  root ||= $('body');

  $(root).find('[data-bs-toggle="popover"]').each(function(qi, q) {
    new bootstrap.Popover(q);
  });
};
