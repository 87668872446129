import $ from 'jquery';
import * as bootstrap from 'bootstrap';

let confirmationDialog = null;

$(function() {
  confirmationDialog = $('#confirmation_dialog');

  if(confirmationDialog.length) {
    new bootstrap.Modal(confirmationDialog.get(0));

    confirmationDialog.on('click', '.modal-footer .btn', function(evt) {
      let clicked = $(this);

      if(clicked.is('.cancel')) {
        if(typeof(confirmationDialog.doCancel) === 'function')
          confirmationDialog.doCancel();
      } else if(clicked.is('.ok')) {
        if(typeof(confirmationDialog.doOk) === 'function')
          confirmationDialog.doOk();
      }
    });
  }
});

export { confirmationDialog };
