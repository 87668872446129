import $ from 'jquery';
import * as bootstrap from 'bootstrap';
import * as ICBootstrap from '../bootstrap';
import * as IC from './form';
import { confirmationDialog } from '../confirmation';

const CRUD = (function() {
  function initializePageActions() {
    $('.instance-actions').on('click', '.btn', function(evt) {
      let clicked = $(this);

      if(clicked.is('.action-destroy, .action-approve, .action-reject, .action-make-public, .action-make-private, .action-reset-password')) {
        evt.stopImmediatePropagation();

        const content = clicked.data('form') ? $(clicked.data('form')).html() : clicked.data('question');
        confirmationDialog.find('.modal-body').html(content);

        confirmationDialog.doOk = function() {
          const act    = `virtual_${clicked.data('method')}`,
                params = clicked.data('form') ? new FormData(confirmationDialog.find('.modal-body').find('FORM').get(0)) : {};
          IC.Form[act](clicked.data('url'), params);
        }
        bootstrap.Modal.getInstance(confirmationDialog.get(0)).show();
      } else if(clicked.is('.action-cancel-entry')) {
        evt.stopImmediatePropagation();

        confirmationDialog.find('.modal-body').html('入力をやめますか？');
        confirmationDialog.doOk = function() {
          let url    = clicked.data('url');
          let method = clicked.data('method');

          if(_.isEmpty(method))
            window.location = url
          else {
            let act = `virtual_${method}`;
            IC.Form[act](url);
          }
        }
        bootstrap.Modal.getInstance(confirmationDialog.get(0)).show();
      }
    });
  }

  return {
    initializePageActions: initializePageActions
  };
}());

export {
  CRUD
};
