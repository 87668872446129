import $ from 'jquery';
import * as bootstrap from 'bootstrap';

function icInitializeBootstrap() {
  if(typeof(window.beforeBootstrapInitialize) === 'function')
    window.beforeBootstrapInitialize();

  if(typeof(window.icInitializeBootstrapMain) === 'function')
    window.icInitializeBootstrapMain();

  if(typeof(window.afterBootstrapInitialize) === 'function')
    window.afterBootstrapInitialize();
}

export {
  icInitializeBootstrap
};
