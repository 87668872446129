import $ from 'jquery';
import * as ICBootstrap from '../../custom/bootstrap';

const Form = (function() {
  // -- Private definitions --

  // virtual_post
  function virtual_post(url, params, method) {
    let f = createForm('POST', url);
    f.style.display = 'none';
    document.body.appendChild(f);

    if(method)
      f.appendChild(createHiddenInput('_method', method));

    let s = createHiddenInput('authenticity_token', authenticityToken());
    f.appendChild(s);

    if(params instanceof FormData) {
      params.forEach((value, key) => {
        f.appendChild(createHiddenInput(key, value));
      });
    } else if(params) {
      for(let key in params)
        f.appendChild(createHiddenInput(key, params[key]));
    }

    f.submit();
  }

  function virtual_put(url, params) {
    virtual_post(url, params, 'put');
  }

  function virtual_delete(url, params) {
    virtual_post(url, params, 'delete');
  }

  function createForm(method, action) {
    let f = document.createElement('form');
    f.method = method;
    f.action = action;
    return f;
  }

  function createHiddenInput(name, value) {
    let m = document.createElement('input');
    m.setAttribute('type',  'hidden');
    m.setAttribute('name',  name);
    m.setAttribute('value', value);
    return m;
  }

/*
  function clear(f) {
    $(':input', f)
      .not(':button, :submit, :reset, :hidden')
      .val('')
      .prop('checked', false)
      .prop('selected', false);
    $(':selected').prop('selected', false);
    }
*/

  function authenticityToken() {
    return document.getElementsByName('csrf-token')[0].getAttribute('content');
  }

  // -- Public methods --
  return {
    virtual_post:      virtual_post,
    virtual_put:       virtual_put,
    virtual_delete:    virtual_delete,
    createForm:        createForm,
    createHiddenInput: createHiddenInput,
    // clear:             clear,
    authenticityToken: authenticityToken
  };
}());

export {
  Form
};
