import $ from 'jquery';
import * as bootstrap from 'bootstrap';

function enableBootstrapValidations(options) {
  options = options || {};

  $('body').on('submit', '.needs-validation', function(evt) {
    $(this).addClass('was-validated');
    if(!this.checkValidity())
      return false;

    if(typeof(options.beforeSubmit) === 'function') {
      if(!options.beforeSubmit())
        return false;
    }

    evt.stopImmediatePropagation();
  });

  console.log('[INFO] Enabling form validations');
}

function clearBootstrapValidationError(field) {
  doBootstrapValidationError('remove', field);
}

function addBootstrapValidationError(field) {
  doBootstrapValidationError('add', field);
}

function doBootstrapValidationError(operation, field) {
  field = $(field);
  field[`${operation}Class`]('is-invalid');
}

export {
  enableBootstrapValidations as enable,
  clearBootstrapValidationError as clearError,
  addBootstrapValidationError as addError
};
