import $ from 'jquery';
import * as ICBootstrap from '../custom/bootstrap';

function clearForm(f) {
  $(':input', f)
    .not('select, :button, :submit, :reset, :hidden, :checkbox, :radio')
    .val('');
  $(':input', f)
    .not(':button, :submit, :reset, :hidden')
    .prop('checked', false)
    .trigger('change');
  $('select', f).each((_fi, field) => {
    if($(field).find('option[value=""]').length)
      $(field).val('');
  });
}

// Advances given date to next day.
// @param  date [Date] The target
// @return [Date] Incrementd date
function incrementDate(date) {
  date.setDate(date.getDate() + 1);
  return date;
}

function icPageInitialize() {
  ICBootstrap.icInitializeBootstrap();
}

function icIsIE11() {
  return !!window.MSInputMethodContext && !!document.documentMode;
}

function icIsMobile() {
  return ('ontouchstart' in window) ? true : false;
}

// Generate string for random DOM ID.
// @see https://gist.github.com/gordonbrander/2230317#gistcomment-2651590
// @return [String] Random ID
function icRandomDomId() {
  return Math.round(Math.pow(Math.random() * 36, 12)).toString(36);
}

export {
  clearForm,
  incrementDate,
  icPageInitialize,
  icIsIE11,
  icIsMobile,
  icRandomDomId
};

export {
  Form
} from '../custom/in_control/form.js';

export {
  Format
} from '../custom/in_control/format.js';

export {
  CRUD
} from '../custom/in_control/crud.js';

export {
  Misc
} from '../custom/in_control/misc.js';
