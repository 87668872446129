import $ from 'jquery';
import * as bootstrap from 'bootstrap';

export default function enableBootstrapToasts() {
  const Z_INDEX_FRONT = 2000;
  const Z_INDEX_BACK  = -10;

  function bringForward(toast) {
    $(toast).css('zIndex', Z_INDEX_FRONT);
  }
  function sendBack(toast) {
    $(toast).css('zIndex', Z_INDEX_BACK);
  }

  $('.toast').each(function(qi, q) {
    sendBack(q);
    new bootstrap.Toast(q);
  });
  $('.toast').on('show.bs.toast', function(evt) {
    bringForward($(this).closest('.toast-container'));
  });
  $('.toast').on('hidden.bs.toast', function(evt) {
    sendBack($(this).closest('.toast-container'));
  });
}
