const Format = (function() {
  // -- Private definitions --

  const FULL_TO_HALF = {
    '０': '0',
    '１': '1',
    '２': '2',
    '３': '3',
    '４': '4',
    '５': '5',
    '６': '6',
    '７': '7',
    '８': '8',
    '９': '9',
    '，': ',',
    '．': '.',
    '。': '.',
    '／': '/',
    '−': '-',
    '－': '-'
  };
  const FULL_TO_HALF_KEYS = new RegExp(`[${Object.keys(FULL_TO_HALF).join('')}]`, 'g');
  const NON_NUMERIC       = /[^\d\.\-]/g;

  function sanitizeFullWidth(value) {
    value = String(value);
    if(value.length > 0) {
      value = value.replace(FULL_TO_HALF_KEYS, function(match) {
        return FULL_TO_HALF[match]
      });
    }
    return value;
  }

  function sanitizeAsNumber(value) {
    return String(value).replace(NON_NUMERIC, '');
  }

  function numberWithDelimiter(value) {
    value = String(value);
    if(value.length > 0) {
      value = value.replace(/(^|[^\w.])(\d{4,})/g,
                            function($0, $1, $2) {
                              return $1 + $2.replace(/\d(?=(?:\d\d\d)+(?!\d))/g, "$&,");
                            });
    }
    return value;
  }

  function parseNumber(value) {
    value = Number(sanitizeAsNumber(sanitizeFullWidth(value)));
    if(isNaN(value))
      value = 0;
    return value;
  }


  // -- Public methods --
  return {
    sanitizeFullWidth:   sanitizeFullWidth,
    sanitizeAsNumber:    sanitizeAsNumber,
    numberWithDelimiter: numberWithDelimiter,
    parseNumber:         parseNumber
  };
}());

export {
  Format
};
