import $ from 'jquery';
import * as bootstrap from 'bootstrap';

export default function enableBootstrapTooltips(root) {
  root ||= $('body');

  $(root).find('[data-bs-toggle="tooltip"]').each(function(qi, q) {
    new bootstrap.Tooltip(q, {
      trigger: 'hover focus'
    });
  });
}
