import $ from 'jquery';
import * as IC from '../in_control/format';
import debugp from '../debug';

const Misc = (function() {
  // -- Private definitions --
  const FIELDS = ['amount', 'tax', 'amount_raw'];

  function taxRateTable() {
    return window.Apos.Tax.RATE_TABLE;
  }

  function taxPrecision() {
    return window.Apos.Tax.precision;
  }

  // Iterator for fields.
  // @param f [Function] Function called for each field
  function eachField(f) {
    $(FIELDS).each(f);
  }

  function tax(value, reference_date, contract_date) {
    value = parseInt(value);
    if(isNaN(value))
      return 0;

    return Math.ceil(value * taxRateOnDate(reference_date, contract_date));
  }

  function withoutTax(value, reference_date, contract_date) {
    value = parseInt(value);
    if(isNaN(value))
      return 0;

    let raw = 0,
        wo  = 1.0 + taxRateOnDate(reference_date, contract_date);
    if(wo != 0)
      raw = value / wo;
    return value >= 0 ? Math.floor(raw) : Math.ceil(raw);
  }

  function taxRateOnDate(reference_date, contract_date) {
    let rate;

    $.each(taxRateTable(), function(_si, schedule) {
      if(!(schedule.start_date && contract_date < schedule.start_date) && !(schedule.end_date && contract_date > schedule.end_date)) {
        $.each(schedule.dates, function(_ri, s_range) {
          if(!(s_range.start_date && reference_date < s_range.start_date) && !(s_range.end_date && reference_date > s_range.end_date)) {
            rate = s_range.rate;
            return;
          }
        })
      }
    });

    debugp(`    [taxRateOnDate] refrence_date=${reference_date}, contract_date=${contract_date} -> ${rate}`);
    return rate;
  }

  // Determines if tax rate calculated from triple is equivalent to given rate.
  // @param triple [Hash] An object with 'amount', 'tax', and 'amount_raw' fields
  // @param tax_rate [Number] Rate against which to compare
  // @return [Boolean] true if rates are equivalent
  function compareTaxRates(triple, tax_rate) {
    debugp(`    [compareTaxRates] triple=${triple['amount_raw']}, ${triple['tax']}, ${triple['amount']}, rate=${tax_rate}`);

    if((Math.abs(triple['amount']) < taxPrecision() / 10 && Math.abs(triple['amount_raw']) < taxPrecision() / 10) ||
      triple['amount_raw'] == 0) {
      debugp('        -> too small');
      return true;
    }

    let triple_rate = triple['tax'] / triple['amount_raw'];

    if(Math.abs(triple_rate - tax_rate) * Apos.config.TaxRatePrecision > 1.0) {
      debugp(`        triple_rate=${triple_rate} -> not equal`);
      return false;
    }

    triple_rate = triple['amount'] / triple['amount_raw'] - 1.0;
    let result = Math.abs(triple_rate - tax_rate) * taxPrecision() <= 1.0;
    debugp(`        triple_rate=${triple_rate} -> ${result}`);

    return result;
  }

  // Fires events to update detail fields if tax rate has changed.
  // @param detail [Hash] An object with 'amount', 'tax', and 'amount_raw' fields
  // @param reference_date [Date] Date passed to taxRateOnDate
  // @param contract_date [Date] Date passed to taxRateOnDate(for tax table)
  // @param get_field [Function] Given field name, yields CSS selector to obtain corresponding field within detail
  // @param recalculate [Function] Given detail value triple, performs required calculations
  // @param return_if [Function] Given detail value triple, determines if we should abandon recalculation
  function checkTaxRateAndRecalculate(detail, reference_date, contract_date, get_field, recalculate, return_if) {
    var originalValues = { amount: 0, tax: 0, amount_raw: 0 },
        detailValues   = {};

    eachField(function(_fi, field) {
      detailValues[field] = detail.find(get_field(field));
      originalValues[field] = IC.Format.parseNumber(detailValues[field].val());
    });
    debugp(`[checkTaxRateAndRecalculate] original=${originalValues['amount_raw']}, ${originalValues['tax']}, ${originalValues['amount']} date=${reference_date}`);

    if(return_if && return_if(detailValues))
      return;

    if(!compareTaxRates(originalValues, taxRateOnDate(reference_date, contract_date)))
      recalculate(detailValues);
  }

  // -- Public methods --
  return {
    eachField:                  eachField,
    tax:                        tax,
    withoutTax:                 withoutTax,
    taxRateOnDate:              taxRateOnDate,
    compareTaxRates:            compareTaxRates,
    checkTaxRateAndRecalculate: checkTaxRateAndRecalculate
  };
}());

export {
  Misc
};
